@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap);
body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2E2E2E;
}
.center-auth {
  color: black;
  min-width: 300px;
  position: absolute;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.auth-container{
  border-top: 5px solid #47B1FF;
  text-align: center;
  background-color: white;
  padding: 15px;
}

.auth-logo {
  width: auto;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.places .ant-list-item-extra img{
    height: 200px !important;
    width: 200px !important;
    border-radius: 7px;
    object-fit: cover;
}

.places .ant-list-vertical .ant-list-item-main{
    background-color: white;
    padding: 20px;
    border-radius: 7px;
}

 h2{
    font-size: 20px;
    font-weight: 300;
    color: rgba(0,0,0,.4);
}
.places-buttons .ant-btn{
    margin-right: 8px;
    margin-bottom: 12px;
}
.places .ant-row{
    background: white;
    padding:20px;
    border-bottom: 5px solid #47B1FF;
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.shadow{
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
}

.ant-list-item-main{
    cursor: pointer;
}

label {
    color: #999;
    -webkit-transform: translate(0.25rem, -1.5rem);
            transform: translate(0.25rem, -1.5rem);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    font-size: 10px;
  }

.required{
    color:red
}

input:focus + label {
color: #111;
-webkit-transform: translate(0, -2.75rem);
        transform: translate(0, -2.75rem);
}

input:focus + label,
input:not(:placeholder-shown) + label  {
color: #111;
-webkit-transform: translate(0, -2.75rem);
        transform: translate(0, -2.75rem);
}

@supports (not (-ms-ime-align:auto)) {
    label {
        color: #999;
        -webkit-transform: translate(0.25rem, -1.5rem);
                transform: translate(0.25rem, -1.5rem);
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
        color: #111;
        -webkit-transform: translate(0, -2.75rem);
                transform: translate(0, -2.75rem);
    }
}
.ant-col {
    padding-bottom: 10px;
}
.places .ant-list-item-extra img{
    height: 200px !important;
    width: 200px !important;
    border-radius: 7px;
    object-fit: cover;
}

.places .ant-list-vertical .ant-list-item-main{
    background-color: white;
    padding: 20px;
    border-radius: 7px;
}

 h2{
    font-size: 20px;
    font-weight: 300;
    color: rgba(0,0,0,.4);
}
.places-buttons .ant-btn{
    margin-right: 8px;
    margin-bottom: 12px;
}
.places .ant-row{
    background: white;
    padding:20px;
    border-bottom: 5px solid #47B1FF;
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.shadow{
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
}

.ant-list-item-main{
    cursor: pointer;
}

label {
    color: #999;
    -webkit-transform: translate(0.25rem, -1.5rem);
            transform: translate(0.25rem, -1.5rem);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    font-size: 10px;
  }

.required{
    color:red
}

input:focus + label {
color: #111;
-webkit-transform: translate(0, -2.75rem);
        transform: translate(0, -2.75rem);
}

input:focus + label,
input:not(:placeholder-shown) + label  {
color: #111;
-webkit-transform: translate(0, -2.75rem);
        transform: translate(0, -2.75rem);
}

@supports (not (-ms-ime-align:auto)) {
    label {
        color: #999;
        -webkit-transform: translate(0.25rem, -1.5rem);
                transform: translate(0.25rem, -1.5rem);
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
        color: #111;
        -webkit-transform: translate(0, -2.75rem);
                transform: translate(0, -2.75rem);
    }
}
.ant-col {
    padding-bottom: 10px;
}
.ant-col {
    padding-bottom: 10px;
}

.ql-editor{
    min-height: 40vh;
}
.ant-col {
    padding-bottom: 10px;
}
.ant-col {
    padding-bottom: 10px;
}
.ant-col {
    padding-bottom: 10px;
}
.ant-col {
    padding-bottom: 10px;
}
.ant-col {
    padding-bottom: 10px;
}

 h1,h2,h3,h4, a{
  font-family: Poppins;
  color:black;
  font-weight: 900;
}

a{
  text-decoration: none !important;
}
.ant-list-item h3{
  text-overflow: ellipsis;
  font-weight: 100;
  font-size: 14px;
}

.ant-list-items{
  background-color: white;
}

.ant-menu-horizontal > .ant-menu-item > a{
  color: black;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

