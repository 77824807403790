.center-auth {
  color: black;
  min-width: 300px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.auth-container{
  border-top: 5px solid #47B1FF;
  text-align: center;
  background-color: white;
  padding: 15px;
}

.auth-logo {
  width: auto;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}