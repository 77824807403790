.places .ant-list-item-extra img{
    height: 200px !important;
    width: 200px !important;
    border-radius: 7px;
    object-fit: cover;
}

.places .ant-list-vertical .ant-list-item-main{
    background-color: white;
    padding: 20px;
    border-radius: 7px;
}

 h2{
    font-size: 20px;
    font-weight: 300;
    color: rgba(0,0,0,.4);
}
.places-buttons .ant-btn{
    margin-right: 8px;
    margin-bottom: 12px;
}
.places .ant-row{
    background: white;
    padding:20px;
    border-bottom: 5px solid #47B1FF;
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.shadow{
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
}

.ant-list-item-main{
    cursor: pointer;
}

label {
    color: #999;
    transform: translate(0.25rem, -1.5rem);
    transition: all 0.2s ease-out;
    font-size: 10px;
  }

.required{
    color:red
}

input:focus + label {
color: #111;
transform: translate(0, -2.75rem);
}

input:focus + label,
input:not(:placeholder-shown) + label  {
color: #111;
transform: translate(0, -2.75rem);
}

@supports (not (-ms-ime-align:auto)) {
    label {
        color: #999;
        transform: translate(0.25rem, -1.5rem);
        transition: all 0.2s ease-out;
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
        color: #111;
        transform: translate(0, -2.75rem);
    }
}